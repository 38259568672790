<template>
    <div class="search_box" v-bind:style="{ height: Height + 'px' }">
        <!-- 文献查找 -->
        <el-container>
            <el-main v-bind:style="{ height: Height + 'px' }" :class="keys == '2'?'':'main_css'" >
                <Intelligent v-if="keys == '1'"></Intelligent>
                <!-- <RETRIEVAL v-else-if="keys == '2'" @Guide="shows" @tabSub="tabSub_"></RETRIEVAL> -->
                <AnsweringAi  v-else-if="keys == '3'"></AnsweringAi>
                <Management v-else-if="keys == '2'" @getTreeData="testData"></Management>
                <TOOL v-else-if="keys == '4'"></TOOL>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import RETRIEVAL from "./search_component/retrieval.vue";
// import MANAGE from "./search_component/manage.vue";
import Management from "./search_component/management.vue";
import TOOL from "./search_component/tool.vue";
import Intelligent from "./search_component/intelligent.vue";
import AnsweringAi from "./search_component/answeringAi.vue";
import { unreadNumber } from "@/Api/manage";
import { applet_login } from "@/Api/translation";
import store from "@/store";
export default {
    data() {
        return {
            Height: 0,
            keys: "",
            total: null,
            dialogVisible: false,
            imgs: [
                "../../assets/imgs/青梨PC端引导图/搜索.jpg",
                "../../assets/imgs/青梨PC端引导图/提交.jpg",
                "../../assets/imgs/青梨PC端引导图/筛选.jpg",
            ],
            imgSrc: "../../assets/imgs/青梨PC端引导图/搜索.jpg",
            one: false,
            two: false,
            three: false,
            intervalId: null,
            timer: "",
            value: 0,
        };
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        // console.log(this.name)
        this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 80;
        };
        // 监听当前页面 显示状态
        window.addEventListener("visibilitychange", this.hanldeVisiblityChange);

        // 当页面被销毁时 移除监听
        this.$on("hook:beforeDestroy", () => {
            // console.info("ws 我被销毁了, 移除监听>>>");
            window.removeEventListener(
                "visibilitychange",
                this.hanldeVisiblityChange
            );
        });
    },

    created() {
        console.log(store.getCookie("token"));
        if (this.$route.params.one == "yes") {
            this.dialogVisible = true;
            // this.imgSrc = this.imgs[0];
            this.one = true;
        }
        if (this.$route.query.webPage) {
            this.keys = this.$route.query.webPage.toString();
        }
        this.unreads();
        this.dataRefreh();
        this.loginAutomatic();
    },
    // 监听路由变化
    watch: {
        $route(to, from) {
            if (to.query.webPage) {
                this.keys = to.query.webPage.toString();
            }
        },
    },
    methods: {
        // 定时刷新数据函数
        dataRefreh() {
            // 计时器正在进行中，退出函数
            if (this.intervalId != null) {
                return;
            }
            // 计时器为空，操作
            this.intervalId = setInterval(() => {
                // console.log("刷新" + new Date());
                this.unreads(); //加载数据函数
            }, 15000);
        },
        // 停止定时器
        clear() {
            clearInterval(this.intervalId); //清除计时器
            this.intervalId = null; //设置为null
        },
        hanldeVisiblityChange() {
            if (document.visibilityState === "hidden") {
                // console.log("ws  hidden>>>关闭定时器");
                this.clear();
            } else if (document.visibilityState === "visible") {
                // console.log("ws  visible>>>启动定时器");
                this.dataRefreh();
            }
        },
        tabSub_(val) {
            console.log('a')
            this.keys = 1;
            this.$router.push({
                name: "Search",
                query: { webPage: 1 },
            });
        },
        unreads() {
            unreadNumber()
                .then((res) => {
                    // console.log(res);
                    if (res.data.data.num > 0) {
                        this.total = res.data.data.num;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        testData(da) {
            // console.log("parent");
            // console.log(da)
            if (da == 0) {
                this.total = "";
            } else {
                this.total = da;
            }
        },
        next(index) {
            // console.log(index)
            if (index == "1") {
                this.one = false;
                this.two = true;
            }
            if (index == "2") {
                this.two = false;
                this.three = true;
            }
            if (index == "3") {
                this.three = false;
                this.dialogVisible = false;
            }
        },
        shows(sh) {
            this.dialogVisible = true;
            this.one = true;
        },
        loginAutomatic() {
      var tmp = Date.parse(new Date()).toString();
      tmp = tmp.substr(0, 10);

      let event = localStorage.getItem("code") + "|" + tmp;
      // console.log(event);
      let codesid = {
        code: event,
      };

      applet_login(codesid)
        .then((res) => {
          // 提交成功 弹出提示框并刷新页面
          console.log(res);
          if (res.data.status == 1) {
          } else {
            if (
              res.data.data &&
              res.data.data.token &&
              res.data.data.token.token
            ) {
              localStorage.setItem("token_USID", res.data.data.token.token);
            }
          }
          if (res.status == 200) {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    },
    components: {
        RETRIEVAL,
        // MANAGE,
        Management,
        TOOL,
        Intelligent,
        AnsweringAi
    },
    destroyed() {
        // 在页面销毁后，清除计时器
        this.clear();
    },
};
</script>

<style lang="less" scoped>
.main_css{
    background: #f9f0f4 !important;
}
.search_box {
    box-sizing: border-box;
    .el-aside {
        background-color: #fff;
        color: #333;
        text-align: center;
        line-height: 200px;
    }

    ::v-deep .el-main {
        min-width: 1130px;
        height: 100%;
        background-color: #e9eef3;
        color: #333;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }

    .el-menu {
        background-color: #fff;
    }
}

.is-active div {
    background-color: #b14a75;
    border-radius: 5px;

    span {
        color: #fff;
    }

    i {
        color: #fff;
    }
}

.el-menu-item {
    padding: 5px 50px !important;
    background-color: #fff;
}

.aside_item {
    width: 100%;
    height: 50px;
    line-height: 50px;

    span {
        color: #b1aec8;
        font-size: 15px;
    }
}

.item {
    line-height: 30px;

    /deep/ .el-badge__content.is-fixed {
        position: absolute;
        top: 0px;
        right: 13px;
        transform: translateY(-50%) translateX(100%);
    }
}

.popContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);

    img {
        z-index: 99;
    }

    .el-button {
        z-index: 100;
        position: absolute;
        top: 200px;
        right: 300px;
        background: rgb(79, 176, 255);
        border: none;
    }
}
</style>

<style>
.dialogOne {
    background-color: none !important;
}

/*标题背景色*/
.dialogOne .el-dialog__header {
    background-color: none !important;
    display: none !important;
}

/*body背景色*/
.dialogOne .el-dialog__body {
    background-color: none !important;
    height: 80vh;
}
</style>